import { calculateDealPrice } from '@oneecosystem/dealshaker-core';
import queryString from 'query-string';
import { pick, isNumber } from '@veraio/core';

export const merchantListParams = options => {
  const { name, categoryId, cities, countryId } = options;

  return queryString.stringify(
    {
      ...pick(options, ['pageNumber', 'pageSize']),
      ...(!!String(name)?.length && { name }),
      ...(isNumber(categoryId) && { categoryId }),
      ...(cities?.length && { cities }),
      ...(isNumber(countryId) && { countryId }),
    },
    { arrayFormat: 'index' },
  );
};

export const merchantOverviewResponse = res => ({ ...res, deals: res.deals?.map(calculateDealPrice) });

export const merchantListCityFilterBody = res => ({
  ...res,
  items: res.items.map((city, index) => ({ id: index + 1, name: city })),
});

export const getMerchantListCityFilterParams = options => {
  const { countryId, cityName } = options;

  return queryString.stringify(
    {
      ...pick(options, ['pageNumber', 'pageSize']),
      ...(isNumber(countryId) && { countryId }),
      ...(!!String(cityName)?.length && { cityName }),
    },
    { arrayFormat: 'index' },
  );
};
